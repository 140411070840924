import * as THREE from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js'; // this is the pointer lock controls api
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'; // this is for the blender files

const renderer = new THREE.WebGLRenderer();

renderer.setSize(window.innerWidth, window.innerHeight);

renderer.shadowMap.enabled = false;

document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

renderer.render(scene, camera);

// the above is boilerplate ^^^^


// These are the colors of the scene
const PCol = {
  C1: 0xb0acb0, //  Grey
  C2: 0xe2dddf, //  Light Grey
  C3: 0x85ebd9, //  Bright Teal
  C4: 0x3d898d, //  Light Dark Teal
  C5: 0x2f404d, //  Dark Teal
  RED: 0xFF0000, // red
  GREEN: 0x00FF00, // green
  BLUE: 0x0000FF, // blue
  NPINK: 0xff5aac
}

// background color
renderer.setClearColor(PCol.C4);



// --- LIGHTS --- //
// const spotlight = new THREE.SpotLight(PCol.C3, 1000);
// scene.add(spotlight);
// spotlight.castShadow = true;
// spotlight.shadow.camera.bottom = 100;
// spotlight.shadow.camera.top = 100;
// spotlight.shadow.camera.left = 100;
// spotlight.shadow.camera.right = 100;
// spotlight.angle = 10000;
// spotlight.position.set(10,10,10);

const pointLight = new THREE.PointLight(PCol.C3, 1000);
scene.add(pointLight);
pointLight.position.set(10,10,10);

const ambLight = new THREE.AmbientLight(PCol.C3, 1);
scene.add(ambLight);
ambLight.position.set(-9,0,9);


const ambLightLamp = new THREE.AmbientLight(PCol.NPINK, .75);
scene.add(ambLightLamp);
ambLightLamp.position.set(8, 4, 9);


const spherellG = new THREE.SphereGeometry(1);
const spherellMat = new THREE.MeshBasicMaterial({
  color: PCol.NPINK
})
const spherell = new THREE.Mesh(spherellG, spherellMat);
scene.add(spherell);
spherell.position.set(8, 5, 9)






// --- ELEMENTS --- //


// the main block //

// Base plane
const MplaneGeo = new THREE.PlaneGeometry(20,20);
const MplaneMat = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane = new THREE.Mesh(MplaneGeo, MplaneMat);
scene.add(plane);
plane.rotation.x = -0.5 * Math.PI;
plane.receiveShadow = true;


// plane roof
const MplaneGeo2 = new THREE.PlaneGeometry(20,20);
const MplaneMat2 = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane2 = new THREE.Mesh(MplaneGeo2, MplaneMat2);
scene.add(plane2);
plane2.rotation.x = -0.5 * Math.PI;
//plane2.castShadow = true;
plane2.position.set(0,6,0);


// contact us plane
const MplaneGeo3 = new THREE.PlaneGeometry(20,20);
const MplaneMat3 = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane3 = new THREE.Mesh(MplaneGeo3, MplaneMat3);
scene.add(plane3);
plane3.rotation.x = -0.5 * Math.PI;
//plane3.castShadow = true;
plane3.position.set(0,0,20);


// About me plane
const MplaneGeo4 = new THREE.PlaneGeometry(20,20);
const MplaneMat4 = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane4 = new THREE.Mesh(MplaneGeo4, MplaneMat4);
scene.add(plane4);
plane4.rotation.x = -0.5 * Math.PI;
//plane4.castShadow = true;
plane4.position.set(20,0,0);

// Education plane
const MplaneGeo5 = new THREE.PlaneGeometry(20,20);
const MplaneMat5 = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane5 = new THREE.Mesh(MplaneGeo5, MplaneMat5);
scene.add(plane5);
plane5.rotation.x = -0.5 * Math.PI;
//plane5.castShadow = true;
plane5.position.set(-20,0,0);

// Experience plane
const MplaneGeo6 = new THREE.PlaneGeometry(20,20);
const MplaneMat6 = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide
});
const plane6 = new THREE.Mesh(MplaneGeo6, MplaneMat6);
scene.add(plane6);
plane6.rotation.x = -0.5 * Math.PI;
//plane6.castShadow = true;
plane6.position.set(0,0,-20);

// animated box in the sky
const MboxGeo = new THREE.BoxGeometry(.5,.5,.5);
const MboxMat = new THREE.MeshStandardMaterial({
  color: PCol.C4,
});
const box1 = new THREE.Mesh(MboxGeo, MboxMat);
scene.add(box1);
box1.position.set(-1, 5, -6);
box1.castShadow = true;

// animated box in the sky2
const MboxGeo2 = new THREE.BoxGeometry(.5,.5,.5);
const MboxMat2 = new THREE.MeshStandardMaterial({
  color: PCol.C4,
});
const box12 = new THREE.Mesh(MboxGeo2, MboxMat2);
scene.add(box12);
box12.position.set(-7, 5, -2.5);
box12.castShadow = true;

//sun ball
const SBGeo = new THREE.SphereGeometry(4);
const SBMat = new THREE.MeshBasicMaterial({
  color: 0x85ebd9
}); 
const SunBall = new THREE.Mesh(SBGeo, SBMat);
scene.add(SunBall);
SunBall.position.set(20,20,20);



// --- LOADING IN GLTF FILES --- //

// the instance of the gltfloader
const gLoader = new GLTFLoader();

// Experiance sign
const ExpSign = new URL('../assets/ExperienceSign.glb', import.meta.url);

gLoader.load(ExpSign.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(3, 0, -9);
});

// Education sign
const EduSign = new URL('../assets/EducationSign.glb', import.meta.url);

gLoader.load(EduSign.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-9, 0, -3);
  model.rotation.y = 70;
});

// about me sign
const AMSign = new URL('../assets/AboutMeSign.glb', import.meta.url);

gLoader.load(AMSign.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(9, 0, 3);
  model.rotation.y = -90;
});

// contact me sign
const CMSign = new URL('../assets/ContactUsSign.glb', import.meta.url);

gLoader.load(CMSign.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-3, 0, 9);
  model.rotation.y = 3;
});

// the wall
const wall1 = new URL('../assets/wall.glb', import.meta.url);

gLoader.load(wall1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-10, 0, 0);
  model.rotation.y = 4.7111;
});
// the wall2
const wall2 = new URL('../assets/wall.glb', import.meta.url);

gLoader.load(wall2.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(0, 0, -10);
  //model.rotation.y = 4.7111;
});
// the wall3
const wall3 = new URL('../assets/wall.glb', import.meta.url);

gLoader.load(wall3.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(10, 0, 0);
  model.rotation.y = -4.7111;
});

// the wall4
const wall4 = new URL('../assets/wall.glb', import.meta.url);

gLoader.load(wall4.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(0, 0, 10);
  //model.rotation.y = -4.7111;
});



// cool stand
const cools1 = new URL('../assets/coolstand.glb', import.meta.url);

gLoader.load(cools1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(7, 0, -7);
  //model.rotation.y = -4.7111;
});

// tourous knot ------------ SAVE THIS FOR WHEN WE ARE DOING THE OUTSKIRTS
const tKnotGeo = new THREE.TorusKnotGeometry( 20, 3, 100, 7, 13, 8); 
const tKnotMat = new THREE.MeshStandardMaterial( { color: PCol.C4 } ); 
const torusKnot = new THREE.Mesh(tKnotGeo, tKnotMat); 
scene.add(torusKnot);
torusKnot.position.set(25,70,-25)


// the bouncing capsule //
const capsuleGeo = new THREE.CapsuleGeometry( .5, .8, 3, 10 ); 
const capsuleMat = new THREE.MeshStandardMaterial( {color: PCol.NPINK} ); 
const capsule = new THREE.Mesh( capsuleGeo, capsuleMat ); 
scene.add( capsule );
capsule.position.set(7,4,-7)


// the boards
const RB1 = new URL('../assets/ReadBoard1.glb', import.meta.url);

gLoader.load(RB1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-4, 0, -4);
  model.rotation.y = .5;
});



// the blocks
const B1 = new URL('../assets/smallblock1.glb', import.meta.url);

gLoader.load(B1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-1.7, 0, -4);
  model.rotation.y = .5;
});


// couch1
const couch1 = new URL('../assets/Couch.glb', import.meta.url);

gLoader.load(couch1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(5, -.5, 8.5);
  model.rotation.y = 1.55;
});

// couch2
const couch2 = new URL('../assets/Couch.glb', import.meta.url);

gLoader.load(couch2.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(8.5, -.5, 5);
  model.rotation.y = 3.1;
});

// lamp1
const lamp1 = new URL('../assets/pinkLamp.glb', import.meta.url);

gLoader.load(lamp1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(8, 0, 9);
  model.rotation.y = 3.1;
});

// About Me Billboard
const AboutMeBill = new URL('../assets/AboutUsBillboard.glb', import.meta.url);

gLoader.load(AboutMeBill.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(14, 0, 0);
  model.rotation.y = -1.6;
});

// cone arrow

const cAgeo = new THREE.ConeGeometry(.3, 1, 5);
const cAmat = new THREE.MeshStandardMaterial({
  color: PCol.C4
});
const coneArrow = new THREE.Mesh(cAgeo, cAmat);
scene.add(coneArrow);

coneArrow.position.set(-4,.5,-3);

coneArrow.rotation.z = -1.5;
coneArrow.rotation.y = .2;



// OUTSIDE CONES COORDS with the accompanying lights

// function createGLTF(nameOf, link, pX, pY, pZ){
//   let nameOf = new URL(link, import.meta.url);

//   gLoader.load(nameOf.href, function(sign){
//     let model = sign.scene;
//     scene.add(model);
//     model.position.set(pX, pY, pZ);
//   });
// }

// createGLTF(test,'../assets/outsideLamp.glb', 0, 0, 0);

// Lamp1
const OCL1 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(29, 0, 9);
});

const OCL1Bulb1Geo = new THREE.OctahedronGeometry(1, 0);
const OCL1Bulb1Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL1Bulb1 = new THREE.Mesh(OCL1Bulb1Geo, OCL1Bulb1Mat);
scene.add(OCL1Bulb1);
OCL1Bulb1.position.set(29,5,9);


// Lamp2
const OCL2 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL2.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(29, 0, -9);
});

const OCL2Bulb2Geo = new THREE.OctahedronGeometry(1, 0);
const OCL2Bulb2Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL2Bulb2 = new THREE.Mesh(OCL2Bulb2Geo, OCL2Bulb2Mat);
scene.add(OCL2Bulb2);
OCL2Bulb2.position.set(29, 5, -9);


// Lamp3
const OCL3 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL3.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-29, 0, -9);
});

const OCL3Bulb3Geo = new THREE.OctahedronGeometry(1, 0);
const OCL3Bulb3Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL3Bulb3 = new THREE.Mesh(OCL3Bulb3Geo, OCL3Bulb3Mat);
scene.add(OCL3Bulb3);
OCL3Bulb3.position.set(-29, 5, -9);


// Lamp4
const OCL4 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL4.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-29, 0, 9);
});

const OCL4Bulb4Geo = new THREE.OctahedronGeometry(1, 0);
const OCL4Bulb4Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL4Bulb4 = new THREE.Mesh(OCL4Bulb4Geo, OCL4Bulb4Mat);
scene.add(OCL4Bulb4);
OCL4Bulb4.position.set(-29, 5, 9);


// Lamp5
const OCL5 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL5.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(9, 0, -29);
});

const OCL5Bulb5Geo = new THREE.OctahedronGeometry(1, 0);
const OCL5Bulb5Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL5Bulb5 = new THREE.Mesh(OCL5Bulb5Geo, OCL5Bulb5Mat);
scene.add(OCL5Bulb5);
OCL5Bulb5.position.set(9, 5, -29);


// Lamp6
const OCL6 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL6.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-9, 0, -29);
});

const OCL6Bulb6Geo = new THREE.OctahedronGeometry(1, 0);
const OCL6Bulb6Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL6Bulb6 = new THREE.Mesh(OCL6Bulb6Geo, OCL6Bulb6Mat);
scene.add(OCL6Bulb6);
OCL6Bulb6.position.set(-9, 5, -29);


// Lamp7
const OCL7 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL7.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(9, 0, 29)
});

const OCL7Bulb7Geo = new THREE.OctahedronGeometry(1, 0);
const OCL7Bulb7Mat = new THREE.MeshBasicMaterial({
  color:PCol.C3
});
const OCL7Bulb7 = new THREE.Mesh(OCL7Bulb7Geo, OCL7Bulb7Mat);
scene.add(OCL7Bulb7);
OCL7Bulb7.position.set(9, 5, 29);


// Lamp8
const OCL8 = new URL('../assets/outsideLamp.glb', import.meta.url);

gLoader.load(OCL8.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-9, 0, 29);
});
const OCL8Bulb8Geo = new THREE.OctahedronGeometry(1, 0);
const OCL8Bulb8Mat = new THREE.MeshBasicMaterial({
  color: PCol.C3
});
const OCL8Bulb8 = new THREE.Mesh(OCL8Bulb8Geo, OCL8Bulb8Mat);
scene.add(OCL8Bulb8);
OCL8Bulb8.position.set(-9, 5, 29);

// Phone
const Phone = new URL('../assets/Phone.glb', import.meta.url);

gLoader.load(Phone.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(5, 0, 25);
  model.rotation.y = 3.7;
});


// MailBox
const MailBox = new URL('../assets/MailBox.glb', import.meta.url);

gLoader.load(MailBox.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(-5, 0, 25);
  model.rotation.y = 2.7;
});

// Contact Me Billboard
const ContMeBill = new URL('../assets/ContactMeSign.glb', import.meta.url);

gLoader.load(ContMeBill.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(0, 0, 25);
  model.rotation.y = 3.15;
});

// rocket shit + rocketshit logic
const rocket1 = new URL('../assets/rocketShip.glb', import.meta.url);

gLoader.load(rocket1.href, function(sign){
  const model = sign.scene;
  scene.add(model);
  model.position.set(50,25,50);
  // also needs rotation
});

// we need to add smoke behind this ship
 



// --- lights for the lamps outside --- //

const OCL1Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL1Light);
OCL1Light.position.set(29, 0, 9);

const OCL2Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL2Light);
OCL2Light.position.set(29,0,-9);

const OCL3Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL3Light);
OCL3Light.position.set(-29, 0, -9);

const OCL4Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL4Light);
OCL4Light.position.set(-29, 0, 9);

const OCL5Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL5Light);
OCL5Light.position.set(9, 0, -29);

const OCL6Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL6Light);
OCL6Light.position.set(-9, 0, -29);

const OCL7Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL7Light);
OCL7Light.position.set(9, 0, 29);

const OCL8Light = new THREE.DirectionalLight(PCol.C3, .1);
scene.add(OCL8Light);
OCL8Light.position.set(-9, 0, 29);



// // test 
// const tspheregeo = new THREE.SphereGeometry(1);
// const tspheremat = new THREE.MeshStandardMaterial({
//   color: 0xffffff
// });
// const tsphere = new THREE.Mesh(tspheregeo,tspheremat);
// scene.add(tsphere);
// //tsphere.position.set(29, 5, -9);







// --- Helpers --- //

// this is a grid helper for the places exact location
const gridH = new THREE.GridHelper(20,20);
scene.add(gridH);

// About us gridH
const AMgridH = new THREE.GridHelper(20,20);
scene.add(AMgridH);
AMgridH.position.set(20,0,0)

// Contact us gridH
const CUgridH = new THREE.GridHelper(20,20);
scene.add(CUgridH);
CUgridH.position.set(0,0,20)

// Education girdH
const EDgridH = new THREE.GridHelper(20,20);
scene.add(EDgridH);
EDgridH.position.set(-20,0,0)

// Experience gridH
const EXPgridH = new THREE.GridHelper(20,20);
scene.add(EXPgridH);
EXPgridH.position.set(0,0,-20)

// this sphere is to represent 0.0.0
const sphere0Geo = new THREE.SphereGeometry(.2);
const sphere0Mat = new THREE.MeshBasicMaterial({
  color: PCol.RED
});
const sphere0 = new THREE.Mesh(sphere0Geo, sphere0Mat);
scene.add(sphere0);

// sphere for ambLight
const sphere1Geo = new THREE.SphereGeometry(.2);
const sphere1Mat = new THREE.MeshBasicMaterial({
  color: PCol.RED
});
const sphere1 = new THREE.Mesh(sphere1Geo, sphere1Mat);
scene.add(sphere1);
sphere1.position.set(-9,0,9);


// --- CAMERA STUFF --- //
camera.position.set(0, 2, 0);
camera.lookAt(-4, 2, -4);


// --- THE CONTROLS --- //
// this is the new pointerlock API at line 2
const controls = new PointerLockControls(camera, renderer.domElement);

renderer.domElement.addEventListener("click", async () => {
  await renderer.domElement.requestPointerLock({
    unadjustedMovement: true,
  });
});

const onKeyDown = function (event) {
  switch (event.code) {
      case 'KeyW':
          controls.moveForward(0.175)
          break
      case 'KeyA':
          controls.moveRight(-0.175)
          break
      case 'KeyS':
          controls.moveForward(-0.175)
          break
      case 'KeyD':
          controls.moveRight(0.175)
          break
  }
}
document.addEventListener('keydown', onKeyDown, false);

torusKnot.lookAt(0,0,0)

// box1.name = 'Box1';

let step = 0;
let speed = 0.01;

// --- THE ANIMATE FUNCTION --- //
function animate(time){


  // --- the box rotations --- //
  box1.rotation.x = time / 1000;
  box1.rotation.y = time / 1000;
  box1.rotation.z = time / 1000;

  box12.rotation.x = time / -500;
  box12.rotation.y = time / -500;
  box12.rotation.z = time / -500;

  capsule.rotation.x = time / -1000;
  capsule.rotation.y = time / -1000;
  capsule.rotation.z = time / -4000;

  // --- the animation maps --- //
  step += speed;
  capsule.position.y = 1.5 + 2 * Math.abs(Math.sin(step));

  coneArrow.position.x = -3 + -1 * Math.abs(Math.sin(step));
  coneArrow.rotation.x = time / 500;

  // --- the outside bulb positions --- //
  OCL1Bulb1.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL1Bulb1.rotation.x = time / -800;
  OCL1Bulb1.rotation.y = time / -800;

  OCL2Bulb2.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL2Bulb2.rotation.x = time / 800;
  OCL2Bulb2.rotation.y = time / 800;

  OCL3Bulb3.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL3Bulb3.rotation.x = time / -800;
  OCL3Bulb3.rotation.y = time / -800;

  OCL4Bulb4.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL4Bulb4.rotation.x = time / 800;
  OCL4Bulb4.rotation.y = time / 800;

  OCL5Bulb5.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL5Bulb5.rotation.x = time / -800;
  OCL5Bulb5.rotation.y = time / -800;

  OCL6Bulb6.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL6Bulb6.rotation.x = time / 800;
  OCL6Bulb6.rotation.y = time / 800;

  OCL7Bulb7.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL7Bulb7.rotation.x = time / -800;
  OCL7Bulb7.rotation.y = time / -800;

  OCL8Bulb8.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL8Bulb8.rotation.x = time / 800;
  OCL8Bulb8.rotation.y = time / 800;
  

  // -- bouncing lights -- //

  OCL1Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL2Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL3Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL4Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL5Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL6Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL7Light.position.y = 4 + .5 * Math.abs(Math.sin(step));
  OCL8Light.position.y = 4 + .5 * Math.abs(Math.sin(step));


  // the tourusKnot
  torusKnot.rotation.z = time / 7000;

  // rocket, rocket, rocket
  


  // this just renders the scene with the camera
  renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener('resize', function(){
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
})